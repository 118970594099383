import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Banner,
  BannerStatus,
  Card,
  Stack,
  Pagination,
} from '@shopify/polaris';
import PageLayout from 'components/PageLayout';
import useShop from 'hooks/useShop';
import mixpanel from 'mixpanel-browser';
import { useHistory, useLocation } from 'react-router-dom';
import t, { parameters as p } from 'lib/translation';
import { GUIDE_URL } from '../../utils/openGuide';
import { PlanType } from '../../utils/plans';
import useSession, { isAccountCreated } from '../../hooks/useSession';
import { Banners } from 'types/Banners';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import BoxLink from '../../components/BoxLink';
import HelpGuideCard from '../../components/HelpGuideCard';
import style from './DashboardPage.module.scss';
import { ExternalMinor } from '@shopify/polaris-icons';
import GettingStartedWithVerisartBanner from '../../components/GettingStartedWithVerisartBanner';
import { useOpenWindowOnVerisartDotCom } from '../../utils/mainWebsiteLogin';
import { getPlatform, useAppBridge } from '../../containers/AppBridge';
import { isPageDisabled } from '../../platform/platform';
import { PRINTS_PATH, TOKEN_GATES_PATH } from '../../AppRoutes';
import useNeedPermissionRefresh from 'hooks/useNeedPermissionRefresh';
import useCreatorMyself from 'hooks/useCreatorMyself';
import { ArtistRelationshipStatus } from 'types/Artist';
import { PrintUiAdvertiseBanner } from '../../components/PrintForm/PrintUiAdvertiseBanner';

const SecondaryBoxLinks: (plan: string) => {
  title: string;
  linkTo: string;
  icon: React.ReactNode;
  subtitle: string;
}[] = (plan: string) => [
  {
    linkTo: '/settings',
    title: t('dashboard.new.secondaryBoxes.settings.title'),
    icon: (
      <img
        style={{ height: 20 }}
        alt={'cog icon'}
        src={'/images/cog-icon.png'}
      />
    ),
    subtitle: t('dashboard.new.secondaryBoxes.settings.subtitle'),
  },
  {
    linkTo: '/plan',
    subtitle: t('dashboard.new.secondaryBoxes.plan.subtitle'),
    icon: (
      <img
        style={{ height: 20 }}
        alt={'cash icon'}
        src={'/images/cash-icon.png'}
      />
    ),
    title: p('dashboard.new.secondaryBoxes.plan.title', t(`plans.${plan}`)),
  },
];

const HelpGuides: {
  image: string;
  title: string;
  subtitle: string;
  buttonText: string;
  linkTo: string;
}[] = [
  {
    image: '/images/guides/nft_minting.png',
    title: t('dashboard.new.helpGuides.minting.title'),
    subtitle: t('dashboard.new.helpGuides.minting.subtitle'),
    buttonText: t('dashboard.new.helpGuides.minting.buttonText'),
    linkTo:
      'https://help.verisart.com/en/articles/6380649-getting-started-guide-shopify-nft-minting',
  },
  {
    image: '/images/guides/certification.png',
    title: t('dashboard.new.helpGuides.certification.title'),
    subtitle: t('dashboard.new.helpGuides.certification.subtitle'),
    buttonText: t('dashboard.new.helpGuides.certification.buttonText'),
    linkTo:
      'https://help.verisart.com/en/articles/5278005-getting-started-guide-certifying-on-shopify',
  },
  {
    image: '/images/guides/tokengating.png',
    title: t('dashboard.new.helpGuides.tokengating.title'),
    subtitle: t('dashboard.new.helpGuides.tokengating.subtitle'),
    buttonText: t('dashboard.new.helpGuides.tokengating.buttonText'),
    linkTo:
      'https://help.verisart.com/en/articles/6480830-add-tokengating-to-your-shopify-store',
  },
  {
    image: '/images/guides/print_service.png',
    title: t('dashboard.new.helpGuides.printService.title'),
    subtitle: t('dashboard.new.helpGuides.printService.subtitle'),
    buttonText: t('dashboard.new.helpGuides.printService.buttonText'),
    linkTo:
      'https://help.verisart.com/en/articles/9497684-launch-your-print-store-on-shopify',
  },
  {
    image: '/images/guides/add_creators.png',
    title: t('dashboard.new.helpGuides.creators.title'),
    subtitle: t('dashboard.new.helpGuides.creators.subtitle'),
    buttonText: t('dashboard.new.helpGuides.creators.buttonText'),
    linkTo:
      'https://help.verisart.com/en/articles/5388239-adding-verified-creators-on-shopify',
  },
  {
    image: '/images/guides/gifting.png',
    title: t('dashboard.new.helpGuides.gifting.title'),
    subtitle: t('dashboard.new.helpGuides.gifting.subtitle'),
    buttonText: t('dashboard.new.helpGuides.gifting.buttonText'),
    linkTo: 'https://docs.verisart.com/reference/shopify-nft-gifting',
  },
  {
    image: '/images/guides/customer_experience.png',
    title: t('dashboard.new.helpGuides.customerExperience.title'),
    subtitle: t('dashboard.new.helpGuides.customerExperience.subtitle'),
    buttonText: t('dashboard.new.helpGuides.customerExperience.buttonText'),
    linkTo:
      'https://help.verisart.com/en/articles/7128158-your-customer-s-experience',
  },
];

interface DashboardPageProps {
  dismissGettingStartedBanner?: (dismissValue: string) => void;
  dismissPrintUiBanner?: (dismissValue: string) => void;
}

const DashboardPage: React.FC<DashboardPageProps> = ({
  dismissGettingStartedBanner,
  dismissPrintUiBanner,
}) => {
  const PrimaryBoxLinks: {
    title: string;
    linkTo: string;
    icon: React.ReactNode;
  }[] = useMemo(
    () => [
      {
        linkTo: '/nft',
        title: t('dashboard.new.primaryBoxes.mint.title'),
        icon: (
          <img
            style={{ height: 20 }}
            src={'/images/landscape-icon.png'}
            alt={'landscape icon'}
          />
        ),
      },
      {
        linkTo: TOKEN_GATES_PATH,
        title: t('dashboard.new.primaryBoxes.tokenGate.title'),
        icon: (
          <img
            style={{ height: 20 }}
            src={'/images/unlock-icon.png'}
            alt={'unlock icon'}
          />
        ),
      },
      {
        linkTo: '/certificate',
        title: t('dashboard.new.primaryBoxes.certificate.title'),
        icon: (
          <img
            style={{ height: 20 }}
            src={'/images/certificate-icon.png'}
            alt={'verisart certificate icon'}
          />
        ),
      },
      {
        linkTo: '/prints',
        title: t('dashboard.new.primaryBoxes.prints.title'),
        icon: (
          <img
            style={{ height: 20 }}
            src={'/images/print-icon.png'}
            alt={'verisart certificate icon'}
          />
        ),
      },
    ],
    []
  );

  const { platform } = useAppBridge();
  const { data: myData } = useCreatorMyself();
  const history = useHistory();
  const location = useLocation();

  const [showLinkedBanner, setShowLinkedBanner] = useState(false);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (search.has('linked_account')) {
      setShowLinkedBanner(true);
      search.delete('linked_account');
      location.search = search.toString();
      history.replace(location);
    }
  }, [history, location]);

  const scroller = useRef<HTMLDivElement | null>(null);
  const cardWidth = useRef<number>();

  const openWindowOnVerisartDotCom = useOpenWindowOnVerisartDotCom();

  const cardWidthChanged = useCallback(
    (rect: DOMRectReadOnly) => (cardWidth.current = rect.width),
    []
  );
  const { targetRef } = useResizeObserver(cardWidthChanged);

  const next = () => {
    if (scroller.current) {
      scroller.current?.scrollBy({
        left: cardWidth.current ?? 0,
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const previous = () => {
    if (scroller.current) {
      scroller.current?.scrollBy({
        left: -(cardWidth.current ?? 0),
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const accountCreated = useSession(isAccountCreated);

  const { isLoading, data } = useShop({
    enabled: accountCreated,
  });

  useEffect(() => {
    mixpanel.track('Shopify Dashboard', {
      platform: getPlatform(),
    });
  }, []);

  const planSuccessParam = new URLSearchParams(location.search).get(
    'change_plan_success'
  );

  const [paymentCompleteModal, setPaymentCompleteModal] = useState(
    !!planSuccessParam
  );

  const { data: needsPermissionRefresh } = useNeedPermissionRefresh();

  const [bannerInfo, setBannerInfo] = useState<{
    title?: string;
    message: string;
    status?: BannerStatus;

    action?: { text: string; url: string };
  } | null>(null);

  useEffect(() => {
    if (needsPermissionRefresh && needsPermissionRefresh.url !== null) {
      setBannerInfo({
        title: t('productForm.upgradePermission.title'),
        message: t('productForm.upgradePermission.message'),
        status: 'warning',
        action: {
          text: t('productForm.upgradePermission.button'),
          url: `${needsPermissionRefresh.url}&redirect_uri=https://${window.location.hostname}/redirect`,
        },
      });
    }
  }, [needsPermissionRefresh]);

  if (isLoading || !data) return <p>Loading...</p>;

  return (
    <PageLayout
      pageProps={{
        title: t('dashboard.new.title'),
        secondaryActions: [
          {
            external: true,
            plain: true,
            content: 'Verisart.com',
            icon: ExternalMinor,
            onAction: () => openWindowOnVerisartDotCom('/dashboard'),
          },
        ],
      }}
      displayedBanners={[Banners.LEGACY_PLAN, Banners.QUOTA_EXCEEDED].concat(
        showLinkedBanner ? [Banners.LINKED_SUCCESS] : []
      )}
    >
      {paymentCompleteModal && (
        <TrialNotificationBanner
          setPaymentCompleteModal={setPaymentCompleteModal}
          planType={planSuccessParam as PlanType}
        />
      )}
      {myData?.myselfData[0]?.status === ArtistRelationshipStatus.PENDING && (
        <div style={{ marginBottom: '16px' }}>
          <Banner
            status="warning"
            title={t('banners.identityVerificationBanner.title')}
            action={{
              content: t('banners.identityVerificationBanner.actionContent'),
              onAction: () =>
                openWindowOnVerisartDotCom(
                  '/settings/account?openVerificationDialog=true'
                ),
            }}
          >
            <p>{t('banners.identityVerificationBanner.description')}</p>
          </Banner>
        </div>
      )}
      <Stack vertical>
        {bannerInfo && (
          <Banner
            title={bannerInfo.title}
            status={bannerInfo.status}
            action={
              bannerInfo.action !== undefined
                ? {
                    content: bannerInfo.action.text,
                    url: bannerInfo.action.url,
                  }
                : undefined
            }
            onDismiss={() => setBannerInfo(null)}
          >
            {bannerInfo.message}
          </Banner>
        )}

        {dismissGettingStartedBanner && getPlatform() !== 'Woo' && (
          <GettingStartedWithVerisartBanner
            dismiss={dismissGettingStartedBanner}
          />
        )}

        {/* {dismissArtBlocksBanner && (
          <ArtBlocksAdvertiseBanner dismiss={dismissArtBlocksBanner} />
        )} */}
        {dismissPrintUiBanner && (
          <PrintUiAdvertiseBanner dismiss={dismissPrintUiBanner} />
        )}
        <Stack distribution={'fillEvenly'}>
          {(isPageDisabled('tokengates', platform) ||
          isPageDisabled('prints', platform)
            ? PrimaryBoxLinks.filter(
                (it) =>
                  it.linkTo !== TOKEN_GATES_PATH && it.linkTo !== PRINTS_PATH
              )
            : PrimaryBoxLinks
          ).map((link, index) => (
            <BoxLink
              key={`${link.title}-${index}`}
              primary
              title={link.title}
              icon={link.icon}
              linkTo={link.linkTo}
            />
          ))}
        </Stack>
        <Stack distribution={'fillEvenly'}>
          {SecondaryBoxLinks(data.shop.planType).map((link, index) => (
            <BoxLink
              key={`${link.title}-${index}`}
              title={link.title}
              icon={link.icon}
              linkTo={link.linkTo}
              subtitle={link.subtitle}
            />
          ))}
        </Stack>
        {getPlatform() !== 'Woo' && (
          <Card title={t('dashboard.new.helpGuides.title')}>
            <Card.Section>
              <div ref={scroller} className={style.scroller}>
                {HelpGuides.map((guide, index) => (
                  <div
                    key={`${guide.title}-${index}`}
                    ref={index === 0 ? targetRef : undefined}
                    className={style.helpGuideCard}
                  >
                    <HelpGuideCard
                      title={guide.title}
                      subtitle={guide.subtitle}
                      image={
                        <img
                          src={guide.image}
                          alt={'NFT minting video'}
                          className={style.helpGuideCardImage}
                        />
                      }
                      buttonText={guide.buttonText}
                      linkTo={guide.linkTo}
                    />
                  </div>
                ))}
              </div>
              <div className={style['pl-1']}>
                <Pagination
                  hasNext
                  hasPrevious
                  onNext={next}
                  onPrevious={previous}
                />
              </div>
            </Card.Section>
          </Card>
        )}
        <Stack.Item>
          <div style={{ marginBottom: '20px' }}>
            {/* Space at the bottom */}
          </div>
        </Stack.Item>
      </Stack>
    </PageLayout>
  );
};

type TrialNotificationBannerParams = {
  setPaymentCompleteModal: (complete: boolean) => void;
  planType: PlanType;
};

const TrialNotificationBanner: React.FC<TrialNotificationBannerParams> = ({
  setPaymentCompleteModal,
  planType,
}) => {
  const planName = t(`plans.${planType}`);

  return (
    <div style={{ marginBottom: '16px' }}>
      <Banner
        status="success"
        onDismiss={() => setPaymentCompleteModal(false)}
        title={p('banners.upsellPaymentComplete.title', planName)}
      >
        <p>
          {t('banners.upsellPaymentComplete.contentPrefix')}{' '}
          <a href={GUIDE_URL} target="_blank" rel="noopener noreferrer">
            {t('banners.upsellPaymentComplete.contentLink')}
          </a>{' '}
          {t('banners.upsellPaymentComplete.contentSuffix')}
        </p>
      </Banner>
    </div>
  );
};

export default DashboardPage;
