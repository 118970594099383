import {
  Banner,
  Card,
  Heading,
  Layout,
  Link,
  Modal,
  PageActions,
  Stack,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import React, { useState, useEffect, useCallback } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
  SubmitErrorHandler,
  UseFormReturn,
  Path,
} from 'react-hook-form';
import ControllerSelectInput from 'components/forms/SelectInput';
import useCreateTokenGate, {
  DUPLICATE_TOKEN_GATE_ERROR,
  GatingMode,
  INVALID_CONTRACT_ADDRESS_ERROR,
  TOKENGATE_DISCOUNT_LIMIT,
} from 'hooks/useCreateTokenGate';
import { TokenGate } from 'hooks/useGetTokenGate';
import { Blockchain } from 'types/Blockchain';
import usePatchTokenGate from 'hooks/usePatchTokenGate';
import formatDate from 'utils/formatDate';
import t from 'lib/translation';
import useDeleteTokenGate from 'hooks/useDeleteTokenGate';
import assert from 'assert';
import { useHistory } from 'react-router-dom';
import { useContextualSaveBarOrExternal } from '../../hooks/useContextualSaveBar';
import withSaveToast from '../../hooks/withSaveToast';
import TokenGateFormInputs, {
  snapshotStateChecker,
  SnapshotState,
} from './TokenGateFormInputs';
import { Toast } from '@shopify/app-bridge/actions';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { FilterTypes, TraitRuleTypes, traitRuleTypes } from './types';
import { useAppBridge } from 'containers/AppBridge';
import { isEnvironmentTest } from '../../constants';
import { PrintFormValues } from 'components/PrintForm/PrintForm';
import useUpdateTokengateDiscount from 'hooks/useUpdateTokengateDiscount';

export const blockchainOptions = [
  {
    value: Blockchain.BASE_MAINNET,
    label: t(`blockchain.${Blockchain.BASE_MAINNET}`),
  },
  {
    value: Blockchain.ETHEREUM,
    label: t(`blockchain.${Blockchain.ETHEREUM}`),
  },
  {
    value: Blockchain.ETHEREUM_SEPOLIA,
    label: t(`blockchain.${Blockchain.ETHEREUM_SEPOLIA}`),
  },
  {
    value: Blockchain.POLYGON,
    label: t(`blockchain.${Blockchain.POLYGON}`),
  },
  ...(isEnvironmentTest()
    ? [
        {
          value: Blockchain.FAKE_ETHEREUM_ON_SEPOLIA,
          label: t(`blockchain.${Blockchain.FAKE_ETHEREUM_ON_SEPOLIA}`),
        },
        {
          value: Blockchain.FAKE_BASE_ON_BASE_SEPOLIA,
          label: t(`blockchain.${Blockchain.FAKE_BASE_ON_BASE_SEPOLIA}`),
        },
      ]
    : []),
];

export type TokenGateFormValues = {
  selectedProduct: string[];
  blockchain?: Blockchain;
  name: string;
  contractAddress: string;
  filters: FilterTypes;
  traitRule: TraitRuleTypes;
  traits: Array<{ key: string; value: string }>;
  timed: 'inbetween' | 'noEnd';
  startedAt: string;
  endedAt?: string;
  gateOwnerType: GatingMode;
  maxPerToken: number | null;
  maxPerWalletPerToken: number;
  allowNewOwner: boolean;
  snapshotAt?: string;
  artBlocksProjectId: string;
  discountPercentage?: string;
};

export type TokenGateRuleValues = {
  maxPerWallet: number;
  maxPerToken: number | null;
  allowNewOwner: boolean;
};

export type TokenGateFormProps = {
  data?: TokenGate;
  formType: 'Create' | 'Edit';
  id?: string;
  onSuccess?: () => void;
  onDelete?: () => void;

  /**
   * If set will preset the chain to this and mark it as disabled (create mode only)
   */
  lockBlockchain?: Blockchain;

  /**
   * If set will preset the contract to this and mark it as disabled (create mode only)
   */
  lockContract?: string;

  /**
   * Used to allow having the save buttons exist outside of this form. If set then this will be invoked with a function
   * which can be called when you're ready to save.
   *
   * If undefined then this form will use the `useContextualSaveBar()` save bar
   */
  setSaveCallback?: (saveCallback: () => void) => void;
};

export const traitsFromFormValues = (values: TokenGateFormValues) => {
  if (values.filters === 'traits') {
    return {
      [values.traitRule]: values.traits,
    };
  }
  return null;
};

export const tokengateRulesValues = (
  type: GatingMode,
  maxPerWallet?: number,
  maxPerToken?: number | null
): TokenGateRuleValues => {
  switch (type) {
    case GatingMode.FUTURE_OWNERS:
      if (!maxPerWallet || !maxPerToken)
        throw new Error('Max per wallet limit must exist in advanced setting');
      return {
        maxPerWallet: maxPerWallet,
        maxPerToken: maxPerToken,
        allowNewOwner: true,
      };
    case GatingMode.NO_FUTURE_OWNERS:
      if (!maxPerWallet)
        throw new Error('Max per wallet limit must exist in advanced setting');
      return {
        maxPerWallet: maxPerWallet,
        maxPerToken: null,
        allowNewOwner: false,
      };
    case GatingMode.SNAPSHOT_OF_OWNER_LIST:
      if (!maxPerWallet)
        throw new Error(
          'Max per wallet limit has been set for snap shot tokengate'
        );
      return {
        maxPerWallet: maxPerWallet,
        maxPerToken: null,
        allowNewOwner: false,
      };
    default:
      throw new Error('Unsupported gate rule type');
  }
};

export const retrieveBackendErrorMessages = (error: AxiosError) => {
  if (
    (error?.response?.data?.message as string).includes(
      TOKENGATE_DISCOUNT_LIMIT
    )
  ) {
    return t('tokenGateForm.error.discountLimit');
  } else if (
    (error?.response?.data?.message as string).startsWith(
      DUPLICATE_TOKEN_GATE_ERROR
    )
  ) {
    return t('tokenGateForm.error.duplicateGate');
  } else if (
    (error?.response?.data?.trace as string).includes(
      INVALID_CONTRACT_ADDRESS_ERROR
    )
  ) {
    return t('tokenGateForm.error.invalidAddress');
  } else {
    return (
      error?.response?.data?.message ??
      error?.message ??
      t('tokenGateForm.error.noSaveChanges')
    );
  }
};

export function validateSnapshotTime<
  T extends TokenGateFormValues | PrintFormValues
>(values: T, methods: UseFormReturn<T>) {
  if (
    values.gateOwnerType === GatingMode.SNAPSHOT_OF_OWNER_LIST &&
    values.snapshotAt !== undefined &&
    values.snapshotAt !== ''
  ) {
    if (
      DateTime.fromISO(values.startedAt).diff(
        DateTime.fromISO(values.snapshotAt),
        'hours'
      ).hours < 1
    ) {
      methods.setError('startedAt' as Path<T>, {
        type: 'custom',
        message:
          'Snapshot date and time must be at least an hour before the start time',
      });
    }
  }
}

export const traitsUpdated = (
  dirtyTraits?: { key?: boolean; value?: boolean }[]
) => {
  if (!dirtyTraits) {
    return false;
  }

  for (let { key, value } of dirtyTraits) {
    if (key || value) {
      return true;
    }
  }

  return false;
};
const TokenGateForm: React.FC<TokenGateFormProps> = ({
  data,
  formType,
  id,
  onSuccess,
  onDelete,
  lockBlockchain,
  lockContract,
  setSaveCallback,
}) => {
  const methods = useForm<TokenGateFormValues>({
    defaultValues: {
      selectedProduct: data?.productIds,
      blockchain: data?.blockchain ?? lockBlockchain,
      name: data?.name ? data.name : '',
      contractAddress: data?.contractAddress ?? lockContract ?? '',
      filters: data?.traits ? 'traits' : 'none',
      allowNewOwner: data?.allowFutureOwners ?? false,
      maxPerWalletPerToken: data?.maxPerWalletPerToken ?? -1,
      maxPerToken: data?.maxPerToken ?? null,
      gateOwnerType:
        data?.snapshotAt != null
          ? GatingMode.SNAPSHOT_OF_OWNER_LIST
          : data?.allowFutureOwners
          ? GatingMode.FUTURE_OWNERS
          : data
          ? GatingMode.NO_FUTURE_OWNERS
          : GatingMode.NO_FUTURE_OWNERS,
      traitRule: data?.traits?.excluding ? 'excluding' : 'including',
      traits: data?.traits?.including || data?.traits?.excluding || [],
      timed: data?.endedAt ? 'inbetween' : 'noEnd',
      startedAt: data?.startedAt ?? DateTime.now().toISO(),
      endedAt: data?.endedAt ?? undefined,
      snapshotAt: data?.snapshotAt ?? undefined,
      artBlocksProjectId: data?.artBlocksProjectId?.toString() ?? '',
      discountPercentage: data?.discountPercentage ?? undefined,
    },
  });
  const appBridge = useAppBridge();
  const isPrint = !!data?.print;
  // `dirtyFields` must be dereferenced here otherwise it will be incorrect if
  // checked in the submit method.
  const {
    formState: { dirtyFields },
  } = methods;
  // get around unused var issue
  if (id === 'i') console.log(dirtyFields);

  const snapshotState = snapshotStateChecker(
    data?.snapshotAt ? new Date(data.snapshotAt) : undefined
  );

  const filters = useWatch({ name: 'filters', control: methods.control });
  const traits = useFieldArray({
    name: 'traits',
    control: methods.control,
  });

  const [tokenGateId, setTokenGateId] = useState(id);
  const [showValidationErrorBanner, setShowValidationErrorBanner] =
    useState(false);

  const [formSuccess, setFormSuccess] = useState(false);

  const { mutateAsync: createGate } = useCreateTokenGate();
  const { mutateAsync: patchGate } = usePatchTokenGate();
  const { mutateAsync: deleteGate } = useDeleteTokenGate();

  const { mutateAsync: updateDiscount } = useUpdateTokengateDiscount();

  const handleUpdateDiscount = async (tgid: string) => {
    if (!tgid) {
      console.error('Token gate ID is missing');
      return;
    }

    try {
      await updateDiscount(tgid);
    } catch (error) {
      if (
        (error?.response?.data?.message as string).includes(
          TOKENGATE_DISCOUNT_LIMIT
        )
      ) {
        setApiError(t('tokenGateForm.error.discountLimit'));
      } else {
        setApiError(error?.response?.data?.message as string);
      }
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const [apiError, setApiError] = useState<string | null>(null);
  const [dismissed, setDismissed] = useState<boolean>(false);

  const history = useHistory();

  const onSubmit = async (values: TokenGateFormValues) => {
    setApiError(null);

    if (
      values.name === '' ||
      values.contractAddress === '' ||
      values.blockchain === undefined ||
      values.startedAt === ''
    ) {
      return;
    }

    validateSnapshotTime<TokenGateFormValues>(values, methods);

    try {
      if (formType === 'Create') {
        const { maxPerWallet, maxPerToken, allowNewOwner } =
          tokengateRulesValues(
            values.gateOwnerType,
            values.maxPerWalletPerToken,
            values.maxPerToken
          );
        const res = await createGate({
          name: values.name,
          contractAddress: values.contractAddress,
          productIds: values.selectedProduct,
          blockchain: values.blockchain,
          traits: traitsFromFormValues(values),
          allowFutureOwners: allowNewOwner,
          maxPerWalletPerToken: maxPerWallet,
          maxPerToken: maxPerToken,
          startedAt: values.startedAt,
          endedAt: values.endedAt || null,
          snapshotAt:
            values.gateOwnerType === GatingMode.SNAPSHOT_OF_OWNER_LIST
              ? values.snapshotAt || null
              : null,
          artBlocksProjectId: values.artBlocksProjectId
            ? parseInt(values.artBlocksProjectId)
            : null,
          gatingMode: values.gateOwnerType,
          discountPercentage: values.discountPercentage || null,
        });
        withSaveToast(
          async () => {
            onSuccess?.();
            return '';
          },
          appBridge.showToast,
          {
            successMessage: t('tokenGateForm.createBanner.title'),
          }
        );
        methods.reset(values);
        setFormSuccess(true);
        setTokenGateId(res.id);
        if (history.location.pathname === '/tokengates/create') {
          history.replace('/tokengates');
        }
      } else {
        if (!tokenGateId) {
          throw new Error('Id cannot be undefined');
        }

        const { maxPerWallet, maxPerToken, allowNewOwner } =
          tokengateRulesValues(
            values.gateOwnerType,
            values.maxPerWalletPerToken,
            values.maxPerToken
          );

        const clearTraits =
          values.filters === 'none' && values.traits.length > 0;

        let removeSnapshotAt = false;
        if (values.gateOwnerType === GatingMode.SNAPSHOT_OF_OWNER_LIST) {
          if (values.snapshotAt === undefined) {
            removeSnapshotAt = true;
          }
        } else {
          removeSnapshotAt = true;
        }

        const removeEndedAt =
          !!methods.formState.dirtyFields.endedAt && !values.endedAt;

        const removeDiscountPercentage =
          !!methods.formState.dirtyFields.discountPercentage &&
          !values.discountPercentage;

        // Note PATCH semantics: null means leave unchanged
        await patchGate({
          name: methods.formState.dirtyFields.name ? values.name : null,
          contractAddress: methods.formState.dirtyFields.contractAddress
            ? values.contractAddress
            : null,
          productIds: methods.formState.dirtyFields.selectedProduct
            ? values.selectedProduct
            : null,
          blockchain: methods.formState.dirtyFields.blockchain
            ? values.blockchain
            : null,
          tokenGateId,
          traits:
            traitsUpdated(methods.formState.dirtyFields.traits) ||
            methods.formState.dirtyFields.traitRule
              ? traitsFromFormValues(values)
              : null,
          allowFutureOwners: allowNewOwner,
          maxPerWalletPerToken: maxPerWallet,
          maxPerToken: maxPerToken,
          clearTraits,
          startedAt: methods.formState.dirtyFields.startedAt
            ? values.startedAt
            : null,
          endedAt: methods.formState.dirtyFields.endedAt
            ? values.endedAt || null
            : null,
          snapshotAt:
            methods.formState.dirtyFields.snapshotAt &&
            values.gateOwnerType === GatingMode.SNAPSHOT_OF_OWNER_LIST
              ? values.snapshotAt
              : null,
          removeSnapshotAt,
          removeEndedAt,
          artBlocksProjectId: methods.formState.dirtyFields.artBlocksProjectId
            ? parseInt(values.artBlocksProjectId)
            : null,
          removeArtBlocksProjectId:
            !!methods.formState.dirtyFields.artBlocksProjectId &&
            !values.artBlocksProjectId,
          removeMaxPerToken: maxPerToken === null,
          gatingMode: values.gateOwnerType,
          printId: data?.print,
          discountPercentage: methods.formState.dirtyFields.discountPercentage
            ? values.discountPercentage || null
            : null,
          removeDiscountPercentage,
        });

        methods.reset({
          ...values,
          ...(clearTraits ? { traitRule: traitRuleTypes[0], traits: [] } : {}),
        });

        withSaveToast(
          async () => {
            setFormSuccess(true);
            return '';
          },
          appBridge.showToast,
          {
            successMessage: t('tokenGateForm.editBanner.title'),
          }
        );
      }
    } catch (err) {
      setApiError(retrieveBackendErrorMessages(err as AxiosError));
      throw err;
    }
  };

  const onInvalid: SubmitErrorHandler<TokenGateFormValues> = (errors) => {
    console.log(errors);
    setShowValidationErrorBanner(true);
  };

  const { handleSubmit } = methods;
  const onSave = useCallback(
    async () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      await handleSubmit(onSubmit, onInvalid)();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSubmit]
  );

  useContextualSaveBarOrExternal(methods, onSave, setSaveCallback);

  const deleteTokenGate = async function () {
    assert(tokenGateId);
    if (onDelete) onDelete();
    try {
      await deleteGate(tokenGateId);
      history.replace('/tokengates');
      const options: Toast.Options = {
        duration: 5000,
        message: '',
      };
      options.message = t('tokenGateForm.deleteBanner.title');
      appBridge.showToast(options);
    } catch (e) {
      console.log('There is an error here');
    }
  };

  // Form reset

  const {
    formState: { isSubmitSuccessful },
    reset,
  } = methods;

  const { getValues } = methods;
  useEffect(() => {
    const resetForm = async () => {
      reset(getValues());
    };

    resetForm();
  }, [reset, isSubmitSuccessful, getValues]);

  const snapshotDateTime = data?.snapshotAt
    ? new Date(data.snapshotAt)
    : undefined;
  const currentTime = new Date();

  return (
    <FormProvider {...methods}>
      <form>
        <Layout>
          {data && data?.usage !== 0 && !dismissed && (
            <Layout.Section>
              <Banner
                onDismiss={() => setDismissed(true)}
                title={t('tokenGateForm.banner1.title')}
                status={'warning'}
              >
                <p>{t('tokenGateForm.banner1.body')}</p>
              </Banner>
            </Layout.Section>
          )}
          {isPrint && data?.print && (
            <Layout.Section>
              <Banner>
                <Stack vertical spacing="tight">
                  <Stack spacing="extraTight">
                    <Heading>{t('tokenGateForm.print.title1')}</Heading>
                    <Heading>
                      <Link
                        onClick={() => {
                          history.push(`/prints/edit/${data.print}`);
                        }}
                      >
                        {t('tokenGateForm.print.title2')}
                      </Link>
                    </Heading>
                    <Heading>{t('tokenGateForm.print.title3')}</Heading>
                  </Stack>
                  <p>{t('tokenGateForm.print.subtitle')}</p>
                </Stack>
              </Banner>
            </Layout.Section>
          )}
          {showValidationErrorBanner && (
            <Layout.Section>
              <Banner status="critical" title="Action required">
                <p>{t('tokenGateForm.error.fieldsErrors')}</p>
              </Banner>
            </Layout.Section>
          )}
          {apiError && (
            <Layout.Section>
              <Banner
                title={t('tokenGateForm.error.noSave')}
                status={'critical'}
              >
                <p>{apiError}</p>
              </Banner>
            </Layout.Section>
          )}
          {data?.templateApplied === false && (
            <Layout.Section>
              <Banner
                title={t('tokenGateForm.error.actionRequired')}
                status={'critical'}
              >
                <Stack spacing="extraTight">
                  <p>{t('tokenGateForm.error.themeNotApplied')}</p>
                  <Link url="https://help.verisart.com/en/articles/6480830-add-tokengating-to-your-shopify-store#h_2cedc27ee3">
                    {t('tokenGateForm.error.learnMore')}
                  </Link>
                </Stack>
              </Banner>
            </Layout.Section>
          )}
          {data?.discountPercentage !== null && data?.discountExist === false && (
            <Layout.Section>
              <Banner
                title={t('tokenGateForm.error.actionRequired')}
                status={'critical'}
              >
                <Stack spacing="extraTight">
                  <p>{t('tokenGateForm.error.disscountMissing')}</p>
                  <Link
                    onClick={
                      tokenGateId
                        ? () => handleUpdateDiscount(tokenGateId)
                        : () => {}
                    }
                  >
                    {'Create discount'}
                  </Link>
                </Stack>
              </Banner>
            </Layout.Section>
          )}
          <TokenGateFormInputs
            methods={methods}
            traits={traits}
            formSuccess={formSuccess}
            filters={filters}
            disabledAddress={!!lockContract}
            disabledSnapshot={
              !!data?.snapshotAt &&
              formType === 'Edit' &&
              (snapshotDateTime === undefined ||
                snapshotDateTime <= currentTime)
            }
            isPrint={isPrint ?? false}
          />
          <Layout.Section secondary>
            {data?.updatedAt && (
              <Card title={t('tokenGateForm.cardTitle')}>
                <Card.Section>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p>{t('tokenGateForm.lastModified')}</p>
                    <TextStyle variation="subdued">
                      {formatDate(data.updatedAt, true)}
                    </TextStyle>
                  </div>
                </Card.Section>
              </Card>
            )}
            <Card>
              <Card.Section>
                <ControllerSelectInput
                  label="Blockchain"
                  name="blockchain"
                  required={t('tokenGateForm.error.chain')}
                  error={methods.formState.errors?.blockchain?.message}
                  options={blockchainOptions}
                  disabled={
                    !!lockBlockchain ||
                    snapshotState === SnapshotState.Completed ||
                    isPrint
                  }
                />
              </Card.Section>
            </Card>
          </Layout.Section>
          {formType === 'Edit' && (
            <Layout.Section fullWidth>
              <PageActions
                secondaryActions={[
                  {
                    content: t('tokenGateForm.deleteButton'),
                    disabled: isPrint,
                    destructive: true,
                    outline: true,
                    onAction: () => {
                      if (data && data?.usage !== 0) {
                        setModalOpen(true);
                      } else {
                        deleteTokenGate();
                      }
                    },
                  },
                ]}
              />
              {isModalOpen && (
                <Modal
                  open={isModalOpen}
                  title={t('tokenGateForm.deleteModalTitle')}
                  primaryAction={{
                    destructive: true,
                    content: 'Delete',
                    onAction: deleteTokenGate,
                  }}
                  secondaryActions={[
                    {
                      content: 'Cancel',
                      onAction: () => setModalOpen(false),
                    },
                  ]}
                  onClose={() => setModalOpen(false)}
                >
                  <Modal.Section>
                    <TextContainer>
                      <p>{t('tokenGateForm.deleteTokenGateModal')}</p>
                    </TextContainer>
                  </Modal.Section>
                </Modal>
              )}
            </Layout.Section>
          )}
        </Layout>
      </form>
    </FormProvider>
  );
};

export default TokenGateForm;
