import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';
import { GatingMode } from './useCreateTokenGate';

export type PatchTokenGateProps = {
  name: string | null;
  productIds: string[] | null;
  blockchain: string | null;
  contractAddress: string | null;
  tokenGateId: string | null;
  traits: {
    including?: { key: string; value: string }[];
    excluding?: { key: string; value: string }[];
  } | null;
  clearTraits: boolean;
  startedAt: string | null;
  endedAt: string | null;
  removeEndedAt: boolean;
  allowFutureOwners: boolean | null;
  maxPerWalletPerToken: number | null;
  maxPerToken: number | null;
  snapshotAt: string | null | undefined;
  removeSnapshotAt: boolean;
  artBlocksProjectId: number | null;
  removeArtBlocksProjectId: boolean;
  removeMaxPerToken: boolean;
  gatingMode: GatingMode;
  printId?: string | null;
  discountPercentage?: string | null;
  removeDiscountPercentage?: boolean;
};

/**
 * Note uses PATCH semantics so a null value means "don't change this field".
 * To clear fields use the `removeXXX` and `clearXXX` fields
 */
export default function usePatchTokenGate() {
  const queryCache = useQueryClient();

  return useMutation(
    async ({
      name,
      productIds,
      blockchain,
      contractAddress,
      tokenGateId,
      traits,
      clearTraits,
      allowFutureOwners,
      maxPerWalletPerToken,
      maxPerToken,
      startedAt,
      endedAt,
      removeEndedAt,
      snapshotAt,
      removeSnapshotAt,
      artBlocksProjectId,
      removeArtBlocksProjectId,
      removeMaxPerToken,
      gatingMode,
      discountPercentage,
      removeDiscountPercentage,
    }: PatchTokenGateProps) => {
      const response = await API().patch(`/shop/tokenGate/${tokenGateId}`, {
        name,
        productIds,
        blockchain,
        contract: contractAddress,
        traits,
        clearTraits,
        allowFutureOwners,
        maxPerWalletPerToken,
        maxPerToken,
        startedAt,
        endedAt,
        removeEndedAt,
        snapshotAt,
        removeSnapshotAt,
        artBlocksProjectId,
        removeArtBlocksProjectId,
        removeMaxPerToken,
        gatingMode,
        discountPercentage,
        removeDiscountPercentage,
      });
      return response.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryCache.invalidateQueries('tokenGates');
        queryCache.invalidateQueries(['tokenGates', variables.tokenGateId]);
        queryCache.invalidateQueries(['print', variables.printId]);
      },
    }
  );
}
