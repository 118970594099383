import {
  Banner,
  Card,
  Layout,
  Link,
  Stack,
  SelectOption,
  PageActions,
  Modal,
  TextContainer,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import t from 'lib/translation';
import useCreatePrintProduct, { Print } from 'hooks/useCreatePrintProduct';
import { Blockchain } from 'types/Blockchain';
import {
  FormProvider,
  SubmitErrorHandler,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useContextualSaveBarOrExternal } from 'hooks/useContextualSaveBar';
import TextInput from 'components/forms/TextInput';
import ControllerSelectInput from 'components/forms/SelectInput';
import {
  blockchainOptions,
  retrieveBackendErrorMessages,
  tokengateRulesValues,
  traitsFromFormValues,
  traitsUpdated,
  validateSnapshotTime,
} from 'components/TokenGateForm/TokenGateForm';
import { snapshotStateChecker } from 'components/TokenGateForm/TokenGateFormInputs';
import {
  FilterTypes,
  TraitRuleTypes,
  traitRuleTypes,
} from 'components/TokenGateForm/types';
import useGetContractMetadata from 'hooks/useGetContractMetadata';
import UsageChargesPanel from 'components/UsageChargesPanel';
import useGetUsageCharges from 'hooks/useGetUsageCharges';
import { isPanelDisabled } from 'platform/platform';
import { useAppBridge } from 'containers/AppBridge';
import { GatingMode } from 'hooks/useCreateTokenGate';
import { DateTime } from 'luxon';
import useGetPrintPreview from 'hooks/useGetPrintPreview';
import withSaveToast from 'hooks/withSaveToast';
import { useHistory } from 'react-router-dom';
import SelectInput from 'components/forms/SelectInput';
import useRelatedArtists from 'hooks/useRelatedArtists';
import { RelationshipDataDetails } from 'types/Artist';
import { AxiosError } from 'axios';
import usePatchPrintDrop from 'hooks/usePatchPrintDrop';
import useDeletePrintDrop from 'hooks/useDeletePrintDrop';
import assert from 'assert';
import { Toast } from '@shopify/app-bridge/actions';
import TokenGateFilters from 'components/TokenGateForm/TokenGateFilters';
import PrintFormProductPreview from './PrintFormProductPreview';
import { useOpenWindowOnVerisartDotCom } from 'utils/mainWebsiteLogin';
import TokenGateSchedule from 'components/TokenGateForm/TokenGateSchedule';
import GateTypeSelection from 'components/TokenGateForm/GateTypeSelection';
import ProfitCalculator from './ProfitCalculator';
import ShippingCard from './ShippingCard';
import TokenPreviewCard from './TokenPreviewCard';
import AuthorizeContractCard from './AuthorizeContractCard';
import TestPrintCard from './TestPrintCard';
import CertificatePreviewCard from './CertificatePreviewCard';
import useGetAuthenticatedShopPrint from 'hooks/useGetAuthenticatedShopPrint';
import useShop from 'hooks/useShop';
import useGetGifForContract from 'hooks/useGetGifForContract';
import { validPositiveNumber } from 'utils/validation';

export interface ArtistDetails {
  id: string;
  firstName: string;
  lastName?: string;
  yearOfBirth?: number;
  signatureImage?: string;
  type: string;
  status: string;
}

export type PrintFormProps = {
  data?: Print;
  formType: 'Create' | 'Edit';
  id?: string;
  onSuccess?: () => void;
  onDelete?: () => void;

  /**
   * Used to allow having the save buttons exist outside of this form. If set then this will be invoked with a function
   * which can be called when you're ready to save.
   *
   * If undefined then this form will use the `useContextualSaveBar()` save bar
   */
  setSaveCallback?: (saveCallback: () => void) => void;
};

export type PrintFormValues = {
  artistId?: string;
  productId: string;
  blockchain: Blockchain | undefined;
  name: string;
  contractAddress: string;
  price: number;
  filters: FilterTypes;
  traitRule: TraitRuleTypes;
  traits: Array<{ key: string; value: string }>;
  selectedProduct: string[];
  timed: 'inbetween' | 'noEnd';
  startedAt: string;
  endedAt?: string;
  gateOwnerType: GatingMode;
  maxPerToken: number | null;
  maxPerWalletPerToken: number;
  allowNewOwner: boolean;
  snapshotAt?: string;
  artBlocksProjectId: string;
  tokenId: string;
  sku: string;
  thumbnail: string | undefined;
  status: 'ACTIVE' | 'DRAFT';
};

const PrintForm: React.FC<PrintFormProps> = ({
  data,
  formType,
  id,
  onSuccess,
  onDelete,
  setSaveCallback,
}) => {
  const methods = useForm<PrintFormValues>({
    defaultValues: {
      artistId: data?.artistId,
      productId: data?.productId,
      blockchain: data?.tokenGate?.blockchain,
      name: data?.tokenGate?.name ?? 'Custom Print with TokenID',
      contractAddress: data?.tokenGate?.contractAddress ?? '',
      filters: data?.tokenGate?.traits ? 'traits' : 'none',
      allowNewOwner: data?.tokenGate?.allowFutureOwners ?? false,
      maxPerWalletPerToken: data?.tokenGate?.maxPerWalletPerToken ?? -1,
      maxPerToken: data?.tokenGate?.maxPerToken ?? null,
      gateOwnerType:
        data?.tokenGate?.snapshotAt != null
          ? GatingMode.SNAPSHOT_OF_OWNER_LIST
          : data?.tokenGate?.allowFutureOwners
          ? GatingMode.FUTURE_OWNERS
          : data
          ? GatingMode.NO_FUTURE_OWNERS
          : GatingMode.NO_FUTURE_OWNERS,
      traitRule: data?.tokenGate?.traits?.excluding ? 'excluding' : 'including',
      traits:
        data?.tokenGate?.traits?.including ||
        data?.tokenGate?.traits?.excluding ||
        [],
      timed: data?.tokenGate?.endedAt ? 'inbetween' : 'noEnd',
      endedAt: data?.tokenGate?.endedAt ?? undefined,
      startedAt: data?.tokenGate?.startedAt ?? DateTime.now().toISO(),
      price: data?.price ?? 100,
      snapshotAt: data?.tokenGate?.snapshotAt ?? undefined,
      artBlocksProjectId: data?.tokenGate?.artBlocksProjectId?.toString() ?? '',
      status: data?.status,
      tokenId: '1',
    },
  });

  const { data: shop } = useShop();
  const { platform } = useAppBridge();
  const appBridge = useAppBridge();
  const history = useHistory();
  const openWindowOnVerisartDotCom = useOpenWindowOnVerisartDotCom();
  const usageCharges = useGetUsageCharges(
    !isPanelDisabled('usage_charges', platform)
  );
  const [printDropId, setPrintDropId] = useState(id);
  const createPrintProductMutation = useCreatePrintProduct();
  const { mutateAsync: patchPrintDrop } = usePatchPrintDrop();
  const { mutateAsync: deletePrintDrop } = useDeletePrintDrop();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  // Edge case where the thumbnail is not ready when the user comes to save so we show the modal,
  // but then becomes available while the modal is open. We want to honour the user's decision and not
  // send the thumbnail.
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);
  const [isSaveModalLoading, setSaveModalLoading] = useState(false);

  const [blockchain, contractAddress, tokenId] = methods.watch([
    'blockchain',
    'contractAddress',
    'tokenId',
  ]);

  const { data: contractPreview } = useGetContractMetadata(
    contractAddress,
    blockchain
  );

  const isArtBlocksContract = contractPreview?.isArtBlocks;
  const artBlocksProjectId = isArtBlocksContract
    ? methods.watch('artBlocksProjectId')
    : undefined;

  const getAuthorizedShopPrint = useGetAuthenticatedShopPrint(
    contractAddress,
    blockchain,
    shop?.shop.id!!,
    artBlocksProjectId !== undefined ? artBlocksProjectId : null
  );

  const { relatedArtists } = useRelatedArtists();

  const artistData: RelationshipDataDetails[] =
    relatedArtists.data?.relatedArtist || [];

  const artistDetailsMap: Record<string, ArtistDetails> = artistData.reduce(
    (map, artist) => {
      map[artist.id] = {
        id: artist.id,
        firstName: artist.attributes.name.firstName,
        lastName: artist.attributes.name.lastName,
        yearOfBirth: artist.attributes.yearOfBirth,
        signatureImage: artist.signatureImage,
        type: artist.type,
        status: artist.status,
      };
      return map;
    },
    {} as Record<string, ArtistDetails>
  );

  const mapToSelectOptions = (
    artists: RelationshipDataDetails[]
  ): SelectOption[] => {
    return artists.map((artist) => {
      const { firstName, lastName } = artist.attributes.name;
      const name = `${firstName} ${lastName || ''}`.trim();
      const yearOfBirth = artist.attributes.yearOfBirth
        ? ` (${artist.attributes.yearOfBirth})`
        : '';
      return {
        label: `${name}${yearOfBirth}`,
        value: artist.id,
      };
    });
  };

  const artistOptions: SelectOption[] = mapToSelectOptions(artistData);

  const [selectedArtistId, setSelectedArtistId] = useState<string | null>(
    data?.artistId || null
  );

  const handleSelectChange = (selected: string) => {
    setSelectedArtistId(selected);
  };

  const selectedArtist = selectedArtistId
    ? artistDetailsMap[selectedArtistId]
    : null;

  const gateOwnerType = methods.watch('gateOwnerType');
  const snapshotAt = methods.watch('snapshotAt');
  const maxPerWalletPerToken = methods.watch('maxPerWalletPerToken');

  useEffect(() => {
    methods.trigger('startedAt');
  }, [gateOwnerType, methods]);
  let snapshotState = snapshotStateChecker(
    snapshotAt ? new Date(snapshotAt) : undefined
  );

  const shouldGeneratePreview =
    !isArtBlocksContract || (isArtBlocksContract && artBlocksProjectId);

  const {
    gifData,
    loading: gifLoading,
    fetchError: gifFetchError,
  } = useGetGifForContract(
    blockchain,
    contractAddress,
    isArtBlocksContract,
    isArtBlocksContract && artBlocksProjectId ? artBlocksProjectId : undefined
  );

  const gifPreviewRef = useRef<HTMLDivElement>(null);
  const [gifStillLoadingBanner, setGifStillLoadingBanner] = useState(false);

  const {
    printPreviewData: printMetadata,
    loading: printPreviewLoading,
    fetchError,
  } = useGetPrintPreview(
    shouldGeneratePreview ? blockchain : null,
    shouldGeneratePreview ? contractAddress : '',
    shouldGeneratePreview ? methods.watch('tokenId') : '',
    data?.productId
  );

  const certificateDescription =
    data?.productCertificateDescription || data?.productDescription;

  const [apiError, setApiError] = useState<string | null>(null);

  const printPreviewError =
    fetchError === 'NFT_NOT_FOUND'
      ? "Can't generate preview as the NFT couldn't be found"
      : fetchError
      ? `Failed to generate preview. Here are some technical details: ${fetchError}. Please contact support@verisart.com`
      : null;

  const gifError =
    gifFetchError === 'NO_CONTRACT_OR_NO_TOKENS'
      ? "Can't generate product image as the contract either didn't exist or contained no tokens"
      : gifFetchError
      ? `Something went wrong. Here are some technical details: ${gifFetchError}. Please contact support@verisart.com`
      : null;

  const [showValidationErrorBanner, setShowValidationErrorBanner] =
    useState(false);

  const onSubmit = async (values: PrintFormValues) => {
    setApiError(null);

    if (
      values.name === '' ||
      values.contractAddress === '' ||
      values.blockchain === undefined ||
      values.startedAt === ''
    ) {
      return;
    }

    validateSnapshotTime<PrintFormValues>(values, methods);

    try {
      if (formType === 'Create') {
        if (!values.thumbnail && !isSaveModalOpen) {
          setSaveModalOpen(true);
          return;
        }
        setSaveModalLoading(true);
        const { maxPerWallet, maxPerToken, allowNewOwner } =
          tokengateRulesValues(
            values.gateOwnerType,
            values.maxPerWalletPerToken,
            values.maxPerToken
          );

        const res = await createPrintProductMutation.mutateAsync({
          price: values.price,
          artistId: values.artistId,
          gate: {
            name: values.name,
            blockchain: values.blockchain,
            contract: values.contractAddress,
            startedAt: values.startedAt,
            traits: traitsFromFormValues(values),
            allowFutureOwners: allowNewOwner,
            maxPerWalletPerToken: maxPerWallet,
            maxPerToken: maxPerToken,
            gatingMode: values.gateOwnerType,
            endedAt: values.endedAt || null,
            snapshotAt:
              values.gateOwnerType === GatingMode.SNAPSHOT_OF_OWNER_LIST
                ? values.snapshotAt || null
                : null,
            artBlocksProjectId: values.artBlocksProjectId
              ? parseInt(values.artBlocksProjectId)
              : null,
          },
          thumbnail: isSaveModalOpen ? null : values.thumbnail,
        });
        withSaveToast(
          async () => {
            onSuccess?.();
            return '';
          },
          appBridge.showToast,
          {
            successMessage: t('printDropForm.createBanner.title'),
          }
        );
        methods.reset(values);
        setPrintDropId(res.id);
        if (history.location.pathname === '/prints/create') {
          history.replace('/prints?printCreated=true');
        }
      } else {
        const { maxPerWallet, maxPerToken, allowNewOwner } =
          tokengateRulesValues(
            values.gateOwnerType,
            values.maxPerWalletPerToken,
            values.maxPerToken
          );

        const clearTraits =
          values.filters === 'none' && values.traits.length > 0;

        let removeSnapshotAt = false;
        if (values.gateOwnerType === GatingMode.SNAPSHOT_OF_OWNER_LIST) {
          if (values.snapshotAt === undefined) {
            removeSnapshotAt = true;
          }
        } else {
          removeSnapshotAt = true;
        }

        const removeEndedAt =
          !!methods.formState.dirtyFields.endedAt && !values.endedAt;

        await patchPrintDrop({
          printId: printDropId ?? '',
          gate: {
            name: methods.formState.dirtyFields.name ? values.name : null,
            //I set this to null because we dont want to update the productIds
            productIds: null,
            blockchain: methods.formState.dirtyFields.blockchain
              ? values.blockchain
              : null,
            contractAddress: methods.formState.dirtyFields.contractAddress
              ? values.contractAddress
              : null,
            tokenGateId: data?.tokenGate?.id ?? null,
            startedAt: methods.formState.dirtyFields.startedAt
              ? values.startedAt
              : null,
            traits:
              traitsUpdated(methods.formState.dirtyFields.traits) ||
              methods.formState.dirtyFields.traitRule
                ? traitsFromFormValues(values)
                : null,
            clearTraits,
            allowFutureOwners: allowNewOwner,
            maxPerWalletPerToken: maxPerWallet,
            maxPerToken: maxPerToken,
            gatingMode: values.gateOwnerType,
            endedAt: methods.formState.dirtyFields.endedAt
              ? values.endedAt || null
              : null,
            removeEndedAt,
            snapshotAt:
              methods.formState.dirtyFields.snapshotAt &&
              values.gateOwnerType === GatingMode.SNAPSHOT_OF_OWNER_LIST
                ? values.snapshotAt
                : null,
            removeSnapshotAt,
            artBlocksProjectId: methods.formState.dirtyFields.artBlocksProjectId
              ? parseInt(values.artBlocksProjectId)
              : null,
            removeArtBlocksProjectId:
              !!methods.formState.dirtyFields.artBlocksProjectId &&
              !values.artBlocksProjectId,
            removeMaxPerToken: maxPerToken === null,
          },
          price: methods.formState.dirtyFields.price ? values.price : null,
          artistId: methods.formState.dirtyFields.artistId
            ? values.artistId
            : null,
          status: methods.formState.dirtyFields.status ? values.status : null,
        });

        methods.reset({
          ...values,
          ...(clearTraits ? { traitRule: traitRuleTypes[0], traits: [] } : {}),
        });

        withSaveToast(
          async () => {
            return '';
          },
          appBridge.showToast,
          {
            successMessage: t('printDropForm.editBanner.title'),
          }
        );
      }
    } catch (err) {
      setApiError(retrieveBackendErrorMessages(err as AxiosError));
      setSaveModalOpen(false);
      throw err;
    }
  };

  useEffect(() => {
    methods.setValue('thumbnail', gifData ?? undefined);
    setGifStillLoadingBanner(false);
  }, [gifData, methods]);

  const onInvalid: SubmitErrorHandler<PrintFormValues> = (errors) => {
    console.log(errors);
    setShowValidationErrorBanner(true);
  };
  const filters = useWatch({ name: 'filters', control: methods.control });
  const traits = useFieldArray({
    name: 'traits',
    control: methods.control,
  });

  const [snapshotDisabled, setSnapshotDisabled] = useState(false);

  useEffect(() => {
    const snapshotDateTime = data?.tokenGate?.snapshotAt
      ? new Date(data.tokenGate.snapshotAt)
      : undefined;
    const currentTime = new Date();
    const isDisabled =
      (!!data?.tokenGate?.snapshotAt &&
        formType === 'Edit' &&
        (snapshotDateTime === undefined || snapshotDateTime <= currentTime)) ||
      methods.watch('snapshotAt') === undefined;
    setSnapshotDisabled(isDisabled);
  }, [data, formType, methods]);

  const { handleSubmit } = methods;

  const onSave = useCallback(
    async () => handleSubmit(onSubmit, onInvalid)(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSubmit, isSaveModalOpen]
  );

  useContextualSaveBarOrExternal(methods, onSave, setSaveCallback);

  const deletePrint = async function () {
    assert(id);
    if (onDelete) onDelete();
    try {
      await deletePrintDrop(id);
      history.replace('/print');
      const options: Toast.Options = {
        duration: 5000,
        message: '',
      };
      options.message = t('printDropForm.deleteBanner.title');
      appBridge.showToast(options);
      history.push('/prints');
    } catch (e) {
      console.log('There is an error here');
    }
  };

  useEffect(() => {
    if (data?.artistId) {
      const currentValues = methods.getValues();

      methods?.reset({
        ...currentValues,
        artistId: data?.artistId,
        maxPerWalletPerToken: data?.tokenGate?.maxPerWalletPerToken ?? -1,
      });
    }
  }, [data?.artistId, data?.tokenGate?.maxPerWalletPerToken, methods]);

  const [artBlocksVerifyError, setArtBlocksVerifyError] = useState<
    string | null
  >(null);

  const onCloseSaveModal = () => {
    setSaveModalOpen(false);

    if (!methods.watch('thumbnail')) {
      setGifStillLoadingBanner(true);
      setApiError('Product image has not finished generating');
      if (gifPreviewRef.current) {
        gifPreviewRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Layout>
          <Layout.Section>
            {data?.tokenGate?.templateApplied === false && (
              <div style={{ marginBottom: '1rem' }}>
                <Banner
                  title={t('tokenGateForm.error.actionRequired')}
                  status={'critical'}
                >
                  <Stack spacing="extraTight">
                    <p>{t('tokenGateForm.error.themeNotApplied')}</p>
                    <Link url="https://help.verisart.com/en/articles/6480830-add-tokengating-to-your-shopify-store#h_2cedc27ee3">
                      {t('tokenGateForm.error.learnMore')}
                    </Link>
                  </Stack>
                </Banner>
              </div>
            )}
            {showValidationErrorBanner && (
              <div style={{ marginBottom: '1rem' }}>
                <Banner status="critical" title="Action required">
                  <p>{t('tokenGateForm.error.fieldsErrors')}</p>
                </Banner>
              </div>
            )}
            {artBlocksVerifyError && (
              <div style={{ marginBottom: '1rem' }}>
                <Banner status="critical">
                  <p>{artBlocksVerifyError}</p>
                </Banner>
              </div>
            )}
            {formType === 'Edit' && selectedArtist?.status === 'PENDING' && (
              <div style={{ marginBottom: '1rem' }}>
                <Banner status="critical" title="Complete creator setup">
                  <Stack vertical spacing="tight">
                    {selectedArtist?.signatureImage && (
                      <p>No signature uploaded</p>
                    )}
                    <Link
                      onClick={() => {
                        const path =
                          selectedArtist?.type === 'SELF'
                            ? '/settings/account?openVerificationDialog=true'
                            : '/settings/authorizations';
                        openWindowOnVerisartDotCom(path);
                      }}
                      external
                    >
                      Manage
                    </Link>
                  </Stack>
                </Banner>
              </div>
            )}
            {apiError && (
              <div style={{ marginBottom: '1rem' }}>
                <Banner
                  title={t('printDropForm.error.noSave')}
                  status={'critical'}
                >
                  <p>{apiError}</p>
                </Banner>
              </div>
            )}
            <Card>
              <Card.Section>
                <Stack vertical>
                  <TextInput
                    name="name"
                    label="Name"
                    disabled={formType === 'Edit'}
                    placeholder={data?.name || 'Custom Print with TokenID'}
                    error={methods.formState.errors?.name?.message}
                  />
                  <TextInput
                    required={t('tokenGateForm.error.contractAddress')}
                    name="contractAddress"
                    label={
                      getAuthorizedShopPrint.data &&
                      getAuthorizedShopPrint.data.registered === true
                        ? `${t(
                            'tokenGateForm.fields.contractAddress'
                          )} (verified)`
                        : t('tokenGateForm.fields.contractAddress')
                    }
                    helpText={
                      getAuthorizedShopPrint.data &&
                      !contractPreview?.isArtBlocks &&
                      getAuthorizedShopPrint.data.registered === true
                        ? 'This contract has been verified and authorized for selling prints.'
                        : undefined
                    }
                    error={methods.formState.errors?.contractAddress?.message}
                    disabled={formType === 'Edit'}
                  />
                  {contractPreview?.isArtBlocks && (
                    <TextInput
                      name="artBlocksProjectId"
                      label={t('tokenGateForm.fields.artBlocksProjectId')}
                      helpText={
                        getAuthorizedShopPrint.data?.artBlocksProjectId &&
                        getAuthorizedShopPrint.data.registered === true
                          ? 'This project has been verified and authorized for selling prints.'
                          : undefined
                      }
                      error={
                        methods.formState.errors?.artBlocksProjectId?.message
                      }
                      validator={validPositiveNumber}
                      maxLength={32}
                      disabled={formType === 'Edit'}
                      required={t('tokenGateForm.error.artBlocksProjectId')}
                    />
                  )}
                </Stack>
              </Card.Section>
            </Card>
            {getAuthorizedShopPrint.data &&
              getAuthorizedShopPrint.data.registered === false && (
                <AuthorizeContractCard
                  contractAddress={contractAddress}
                  blockchain={blockchain}
                  methods={methods}
                  shopId={shop?.shop.id!!}
                  contractPreview={contractPreview}
                  onVerificationError={setArtBlocksVerifyError}
                />
              )}
            <Card>
              <Card.Section>
                <SelectInput
                  name={'artistId'}
                  label={'Creator'}
                  options={artistOptions}
                  customOnChange={handleSelectChange}
                />
              </Card.Section>
            </Card>
            <TokenPreviewCard
              methods={methods}
              printMetadata={printMetadata}
              printPreviewLoading={printPreviewLoading}
              error={printPreviewError}
              contractPreview={contractPreview}
            />
            <ProfitCalculator methods={methods} />
            <ShippingCard />
            {usageCharges && (
              <UsageChargesPanel
                usageCharges={usageCharges.data?.usageCharge}
              />
            )}
            <div
              ref={gifPreviewRef}
              style={{ marginBottom: '1rem', marginTop: '1rem' }}
            >
              <Card
                title="Product preview"
                actions={
                  formType === 'Edit'
                    ? [
                        {
                          content: 'Edit',
                          onAction: () => {
                            const productId = data?.productId.toString();
                            if (typeof productId === 'string') {
                              appBridge.redirect('Admin', {
                                type: 'product',
                                id: productId,
                                newContext: false,
                              });
                            }
                          },
                        },
                      ]
                    : []
                }
              >
                <Card.Section>
                  {gifStillLoadingBanner && !gifError && (
                    <div style={{ marginBottom: '1rem' }}>
                      <Banner status="warning">
                        {t('printDropForm.error.gifLoading')}
                      </Banner>
                    </div>
                  )}
                  {gifError && (
                    <div style={{ marginBottom: '1rem' }}>
                      <Banner status="critical">
                        <p>{gifError}</p>
                      </Banner>
                    </div>
                  )}
                  <PrintFormProductPreview
                    productId={data?.productId ? data.productId : ''}
                    formType={formType}
                    title={methods.watch('name')}
                    gifData={gifData}
                    gifLoading={gifLoading}
                  />
                </Card.Section>
              </Card>
            </div>
            <CertificatePreviewCard
              methods={methods}
              formType={formType}
              data={data}
              relatedArtists={relatedArtists}
              selectedArtist={selectedArtist}
              contractPreview={contractPreview}
              tokenIdInput={tokenId}
              printPreviewHeight={printMetadata?.paper.height}
              printPreviewWidth={printMetadata?.paper.width}
              certificateDescription={certificateDescription}
            />
            <TokenGateSchedule methods={methods} />
            <GateTypeSelection
              methods={methods}
              snapshotState={snapshotState}
              snapshotAt={snapshotAt}
              maxPerWalletPerToken={maxPerWalletPerToken}
              disabled={snapshotDisabled}
            />
            <TokenGateFilters
              filters={filters}
              traits={traits}
              contractPreview={contractPreview}
              methods={methods}
            />
          </Layout.Section>
          <Layout.Section secondary>
            <Card>
              <Card.Section>
                <ControllerSelectInput
                  label="Blockchain"
                  name="blockchain"
                  required={t('tokenGateForm.error.chain')}
                  error={methods.formState.errors?.blockchain?.message}
                  options={blockchainOptions}
                  disabled={formType === 'Edit'}
                />
              </Card.Section>
            </Card>
            {formType === 'Edit' && (
              <Card>
                <Card.Section>
                  <Stack vertical>
                    <SelectInput
                      name={'status'}
                      label={'Shop Product status'}
                      options={[
                        { label: 'Active', value: 'ACTIVE' },
                        { label: 'Draft', value: 'DRAFT' },
                      ]}
                    />
                  </Stack>
                </Card.Section>
              </Card>
            )}
            <TestPrintCard />
          </Layout.Section>
          {formType === 'Edit' && (
            <Layout.Section fullWidth>
              <PageActions
                secondaryActions={[
                  {
                    content: t('tokenGateForm.deleteButton'),
                    destructive: true,
                    outline: true,
                    onAction: () => {
                      setDeleteModalOpen(true);
                    },
                  },
                ]}
              />
              <Modal
                open={isDeleteModalOpen}
                title={t('tokenGateForm.deleteModalTitle')}
                primaryAction={{
                  destructive: true,
                  content: 'Delete',
                  onAction: deletePrint,
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => setDeleteModalOpen(false),
                  },
                ]}
                onClose={() => setDeleteModalOpen(false)}
              >
                <Modal.Section>
                  <TextContainer>
                    <p>{t('printDropForm.deletePrintDrop')}</p>
                  </TextContainer>
                </Modal.Section>
              </Modal>
            </Layout.Section>
          )}
          <Modal
            open={isSaveModalOpen}
            title={t('tokenGateForm.deleteModalTitle')}
            primaryAction={{
              content: 'Save',
              onAction: onSave,
              disabled: isSaveModalLoading,
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: onCloseSaveModal,
                disabled: isSaveModalLoading,
              },
            ]}
            onClose={onCloseSaveModal}
          >
            <Modal.Section>
              <TextContainer>
                <p>{t('printDropForm.savePrintDrop')}</p>
              </TextContainer>
            </Modal.Section>
          </Modal>
        </Layout>
      </form>
    </FormProvider>
  );
};

export default PrintForm;
