import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';
import { TokenGate } from './useGetTokenGate';
import { AxiosError } from 'axios';

export enum GatingMode {
  SNAPSHOT_OF_OWNER_LIST = 'SNAPSHOT_OF_OWNER_LIST',
  NO_FUTURE_OWNERS = 'NO_FUTURE_OWNERS',
  FUTURE_OWNERS = 'FUTURE_OWNERS',
}

export type CreateTokenGateProps = {
  name: string;
  productIds: string[];
  blockchain: string;
  contractAddress: string;
  traits: {
    including?: { key: string; value: string }[];
    excluding?: { key: string; value: string }[];
  } | null;
  allowFutureOwners: boolean;
  maxPerWalletPerToken: number;
  maxPerToken: number | null;
  gatingMode: GatingMode;
  startedAt: string;
  endedAt: string | null;
  snapshotAt: string | null;
  artBlocksProjectId: number | null;
  discountPercentage: string | null;
};

export default function useCreateTokenGate() {
  const queryCache = useQueryClient();

  return useMutation<TokenGate, AxiosError, CreateTokenGateProps>(
    async ({
      name,
      productIds,
      blockchain,
      contractAddress,
      traits,
      allowFutureOwners,
      maxPerWalletPerToken,
      maxPerToken,
      startedAt,
      endedAt,
      snapshotAt,
      artBlocksProjectId,
      gatingMode,
      discountPercentage,
    }) => {
      const response = await API().post('/shop/tokenGate', {
        name,
        productIds,
        blockchain,
        contract: contractAddress,
        traits,
        startedAt,
        endedAt,
        allowFutureOwners,
        maxPerWalletPerToken,
        maxPerToken,
        snapshotAt,
        artBlocksProjectId,
        gatingMode,
        discountPercentage,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('tokenGates');
      },
    }
  );
}

export const DUPLICATE_TOKEN_GATE_ERROR = 'tokengate.duplicate';
export const INVALID_CONTRACT_ADDRESS_ERROR = 'invalid.ethereum.address';
export const TOKENGATE_DISCOUNT_LIMIT =
  'The active period overlaps with that of another price rule';
